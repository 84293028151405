import { useHydrated } from 'remix-utils';

import Shimmer, { links as shimmerComponentStyles } from './Shimmer';

import type { LinksFunction } from '@remix-run/node';
import type { CSSProperties } from 'react';

export const links: LinksFunction = () => {
  return [...shimmerComponentStyles()];
};

type Props = {
  shimmerStyles?: CSSProperties;
  shimmerVariant?: 'image' | 'default';
  time: string;
  useShimmerLoader?: boolean;
};

const HydratedTimezone = ({
  shimmerStyles = { width: '60px', height: '16px' },
  shimmerVariant = 'default',
  time,
  useShimmerLoader = false,
}: Props) => {
  const isHydrated = useHydrated();

  if (!isHydrated)
    return useShimmerLoader ? <Shimmer styles={{ ...shimmerStyles }} variant={shimmerVariant} /> : <div />;

  return <>{time}</>;
};

export default HydratedTimezone;
